import Img_1 from './Media/img_1.png';
import img1 from './Media/img1.jpg';
import img2 from './Media/img2.png';
import img4 from './Media/img4.png';
import img5 from './Media/img5.png';
import img6 from './Media/img6.jpg';
import hand from './Media/Sup.png';
import logo1 from './Media/МТС лого.png';
import logo2 from './Media/Мегафон лого.png';
import logo3 from './Media/Билайн лого.png';
// import logo4 from './Media/Теле2 лого.png';
import logo4 from './Media/Logo_T2.svg';
import logo5 from './Media/Мечта лого.png';
import logo6 from './Media/Эврика лого.png';
import './App.css';
import {postData} from './util';

import Header from "./Header";
import Reviews from "./Reviews";
import {useTranslation} from 'react-i18next';
import './i18n';
import React, {useState} from 'react';
import FormForClients from "./FormForClients";
import Block_content from "./Block_content";

function App() {
    const {t} = useTranslation();
    const handleButtonClick = () => {
        // Get the values from the input fields
        const name1 = document.getElementById('Inp_tg_name').value;
        const name2 = document.getElementById('Inp_mail_name').value;
        const name3 = document.getElementById('Inp_call_name').value;
        const contact1 = document.getElementById('Inp_tg').value; // Assuming this is the correct input id
        const contact2 = document.getElementById('Inp_mail').value;
        const contact3 = document.getElementById('Inp_call').value;
        const postDataObject = {
            name1: name1,
            name2: name2,
            name3: name3,
            contact1: contact1,
            contact2: contact2,
            contact3: contact3,
        };
        postData('/api/call_buyps/call_buyps?type=' + name1 + ' ' + name2 + ' ' + name3 + '&contact=' + contact1 + ' ' + contact2 + ' ' + contact3, postDataObject)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const [modalOpen, setModalOpen] = useState(false);
    const [caseType, setCaseType] = useState('');
    const openModal = (type) => {
        setCaseType(type);
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <main className="App">
            <header className="App-header">
                <Header/>
            </header>

            <body className="Body">
            <h1 id="Title_0">{t('Main.title')}</h1>

            <div id="Block_1">
                <img src={Img_1} id="Img_1"/>
                <a href="#up" id="nav">{t('Main.buy_franchise')}</a>
                <a href="https://buyps.ru/api/v1/apk" id="nav_apk">{t('Main.download_apk')}</a>
                <p className="Text">{t('Main.price')}</p>
            </div>

            <div className="Block_column">
                <h1 className="Title_block_Col">{t('Solutions.title')}</h1>
                <h2 className="Subtitle_block_Col">{t('Solutions.subtitle')}</h2>
                <span className="Descript_block_Col">{t('Solutions.description')}</span>
            </div>

            <Block_content
                title={t('Block_content.0.title')}
                subtitle={t('Block_content.0.subtitle')}
                description={t('Block_content.0.description', {returnObjects: true})}
                imgSrc={img1}
                bgColor="#FF5B5B"
                textAlign="left"
            />
            <Block_content
                title={t('Block_content.1.title')}
                subtitle={t('Block_content.1.subtitle')}
                description={t('Block_content.1.description', {returnObjects: true})}
                imgSrc={img2}
                bgColor="#839AAD"
                textAlign="right"
            />
            <div className="Block_column">
                <h1 className="Title_block_Col">{t('Support.title')}</h1>
                <h2 className="Subtitle_block_Col">{t('Support.subtitle')}</h2>
                <span className="Descript_block_Col">{t('Support.description')}</span>
            </div>
            <Block_content
                title={t('Block_content.2.title')}
                subtitle={t('Block_content.2.subtitle')}
                description={t('Block_content.2.description', {returnObjects: true})}
                imgSrc={img4}
                bgColor="#55735b"
                textAlign="right"
            />
            <Block_content
                title={t('Block_content.3.title')}
                subtitle={t('Block_content.3.subtitle')}
                description={t('Block_content.3.description', {returnObjects: true})}
                imgSrc={img5}
                bgColor="#655572"
                textAlign="left"
            />
            <Block_content
                title={t('Block_content.4.title')}
                subtitle={t('Block_content.4.subtitle')}
                description={t('Block_content.4.description', {returnObjects: true})}
                imgSrc={img6}
                bgColor="#d7c209"
                textAlign="right"
            />

            <div className="Block_column" style={{margin: '40px auto 20px'}}>
                <h1 className="Title_block_Col">{t('Reviews_title.title')}</h1>
                <h2 className="Subtitle_block_Col">{t('Reviews_title.subtitle')}</h2>
            </div>
            <Reviews/>

            <a name="up"></a>
            <div className="Block_column">
                <h1 className="Title_block_Col">{t('Contact_form.title')}</h1>
                <span className="Descript_block_Col">{t('Contact_form.description')}</span>
            </div>

            <div className='Communication_block'>
                <div style={{display: 'flex', flexDirection: 'column', alignContent: 'space-between'}}>
                    {t('Contact_form.options', {returnObjects: true}).map((option, index) => (
                        <button key={index} className='ButtonForm'
                                onClick={() => openModal(option.key)}>{option.label}</button>
                    ))}
                </div>
                <img src={hand} id="Hand"/>
            </div>
            {modalOpen && <FormForClients caseType={caseType} closeModal={closeModal}/>}

            <div className="Block_column" style={{position: 'relative'}}>
                <a name="About" className='Anchor'></a>
                <h1 className="Title_block_Col">{t('About_us.title')}</h1>
                <h2 className="Subtitle_block_Col">{t('About_us.subtitle')}</h2>
            </div>

            <div id="Class_frame">
                <div className="Frame">
                    <p>{t('About_us.details_1.line1')}<br/>{t('About_us.details_1.line2')}</p>
                </div>
                <div className="a1"></div>
                <div className="Frame">
                    <p>{t('About_us.details_2.line1')}<br/>{t('About_us.details_2.line2')}</p>
                </div>
                <div className="a1"></div>
                <div className="Frame">
                    <p>{t('About_us.details_3.line1')}<br/>{t('About_us.details_3.line2')}</p>
                </div>
            </div>

            <div style={{position: 'relative'}}>
                <a name="Partners" className='Anchor'></a>
                <p className="Title_block_Col">{t('Partners.title')}</p>
                <ul id="Part">
                    {t('Partners.list', {returnObjects: true}).map((link, index) => (
                        <li key={index}><a href={link} target="_blank"><img
                            src={index === 0 ? logo1 : index === 1 ? logo2 : index === 2 ? logo3 : index === 3 ? logo4 : index === 4 ? logo5 : logo6}/></a>
                        </li>
                    ))}
                </ul>
            </div>

            <p className="Title_block_Col">{t('Contacts.title')}</p>

            <div id="Contacts" style={{position: 'relative'}}>
                <a name="Contacts" className='Anchor'></a>
                <div id="Map_0">
                    <div id="Map">
                        <a id="Map_b1" href={t('Contacts.map')}>Москва</a>
                        <a id="Map_b2" href={t('Contacts.map')}>Филёвский бульвар, 6 — Яндекс Карты</a>
                        <iframe
                            src="https://yandex.ru/map-widget/v1/?ll=37.489355%2C55.764947&mode=whatshere&whatshere%5Bpoint%5D=37.489108%2C55.764986&whatshere%5Bzoom%5D=17&z=18.02"></iframe>
                    </div>
                </div>

                <div id="Contacts_block">
                    <div className="Contact_block">
                        <p>{t('Contacts.phone_type')}</p>
                        <a className="hhh" href={`tel:${t('Contacts.phone')}`}>{t('Contacts.phone')}</a>
                    </div>
                    <div className="B"></div>
                    <div className="Contact_block">
                        <p>{t('Contacts.email_type')}</p>
                        <a className="hhh" href={`mailto:${t('Contacts.email')}`}>{t('Contacts.email')}</a>
                    </div>
                    <div className="B"></div>
                    <div className="Contact_block">
                        <p>{t('Contacts.address_type')}</p>
                        <p className="price-list__title">{t('Contacts.address')}</p>
                    </div>
                </div>
            </div>

            <div id="Line_down"></div>
            <div id="Text_down">
                {t('Footer.text')}
            </div>
            <a name="Cont"></a>
            </body>
        </main>
  );
}

export default App;

